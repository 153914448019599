import { useLingui } from '@lingui/react';
import { useAppConfiguration } from '@wkda/funnel-components';
export const useMinimumMileageValidation = () => {
  const {
    i18n
  } = useLingui();
  // @ts-ignore
  const {
    minimumMileage
  } = useAppConfiguration();
  return {
    createCustomValidators: [function mileageValidator(_ref) {
      let {
        validators
      } = _ref;
      return {
        ...validators,
        mileage(_ref2) {
          let {
            value,
            errorMessages,
            values
          } = _ref2;
          const {
            builtDate
          } = values;
          const maxMileageValue = 999999999;
          const minMileageValue = 0;
          if (minimumMileage && isMileageInvalidForYear(builtDate, value)) {
            return i18n._('carleadCreation.error.minimumMileage');
          }
          if (mileageIsOutOfBounds(value)) {
            return errorMessages.tooLongMileage;
          }
          return notEmpty(value) && Number(value) > minMileageValue && Number(value) <= maxMileageValue || errorMessages.mileage;
        }
      };
    }]
  };
};
function isEmpty() {
  let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // eslint-disable-next-line eqeqeq
  return value == undefined || value === '';
}
function notEmpty() {
  let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return !isEmpty(value);
}
function mileageIsOutOfBounds() {
  let mileage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const maxMileageValue = 999999999;
  if (notEmpty(mileage) && Number(mileage) > maxMileageValue) {
    return true;
  }
  return false;
}
function isMileageInvalidForYear() {
  let builtDate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let mileage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  const currentYear = new Date().getFullYear();
  const builtYear = parseInt(builtDate, 10);
  // Custom validation logic
  if (builtYear < currentYear - 1 && parseInt(mileage, 10) < 1000) {
    return true;
  }
  return false;
}
import { focus } from '@wkda/funnel-components';
import Cookies from 'universal-cookie';
import { assign, raise } from 'xstate';
import { isFillInputEvent, isFocusInputEvent, isSelectEvent } from '../../machineModels/eventGenerators';
import { valueIsEmpty } from './guards';
export function focusOnEnterAction(_ref, params) {
  var _params$fieldName;
  let {
    context
  } = _ref;
  const stepName = (_params$fieldName = params.fieldName) !== null && _params$fieldName !== void 0 ? _params$fieldName : '';
  const domObject = document.querySelector("[name=\"" + stepName + "\"]");
  if (domObject && Array.isArray(context.stack) && !context.stack.includes(stepName)) {
    focus(domObject);
  }
}
export function fillInputAction() {
  return assign((_ref2, params) => {
    let {
      context: {
        values,
        selectedFields,
        stack
      },
      event
    } = _ref2;
    if (!isFillInputEvent(event)) {
      return {};
    }
    const {
      fieldName,
      selected
    } = event;
    const isInStack = stack.includes(fieldName);
    let newSelectedFields = {
      ...selectedFields
    };
    let newStack = [...stack];
    if (valueIsEmpty(selected)) {
      clearSelectedFieldsForGroup(fieldName, newSelectedFields);
      if (isInStack) {
        newStack = resetStackUntil(stack, fieldName);
      }
    } else {
      const nextField = params.nextField;
      newSelectedFields[fieldName] = nextField;
    }
    return {
      values: {
        ...values,
        [fieldName]: selected
      },
      selectedFields: newSelectedFields,
      previousSelectedFieldsMap: reverseObject(newSelectedFields),
      stack: newStack
    };
  });
}
export function assignOnPreloadAndClearAction() {
  return assign((_ref3, params) => {
    var _params$fieldName2;
    let {
      context: {
        preloadedData
      }
    } = _ref3;
    const stepToPreload = (_params$fieldName2 = params.fieldName) !== null && _params$fieldName2 !== void 0 ? _params$fieldName2 : '';
    const stepValueToPreload = preloadedData[stepToPreload];
    const updated = {
      ...preloadedData
    };
    delete updated[stepToPreload];
    return {
      stepToPreload,
      stepValueToPreload,
      preloadedData: {
        ...updated
      }
    };
  });
}
export function assignDataOnSelectAction() {
  return assign((_ref4, params) => {
    let {
      context: {
        values,
        selectedFields,
        stack
      },
      event
    } = _ref4;
    if (!isSelectEvent(event)) {
      return {};
    }
    const {
      fieldName,
      selected
    } = event;
    const isInStack = stack.includes(fieldName);
    const newSelectedFields = {
      ...selectedFields,
      [fieldName]: params.nextField
    };
    return {
      values: {
        ...values,
        [fieldName]: selected
      },
      selectedFields: newSelectedFields,
      previousSelectedFieldsMap: reverseObject(newSelectedFields),
      updatingField: '',
      ...(isInStack ? {} : {
        stack: stack.concat([fieldName])
      })
    };
  });
}
export function updateStackAction() {
  return assign((_ref5, params) => {
    let {
      context: {
        stack,
        selectedFields
      }
    } = _ref5;
    const fieldName = params.fieldName;
    const nextField = params.nextField;
    const isInStack = stack.includes(fieldName);
    const newSelectedFields = {
      ...selectedFields,
      [fieldName]: nextField
    };
    return {
      stack: !isInStack ? stack.concat(fieldName) : stack,
      selectedFields: newSelectedFields,
      previousSelectedFieldsMap: reverseObject(newSelectedFields)
    };
  });
}
export function clearResetToAction() {
  return assign(() => {
    return {
      resetTo: '',
      resetValue: '',
      resetType: '',
      stepToPreload: '',
      stepValueToPreload: ''
    };
  });
}
export function selectWithPreloadedAction() {
  return raise(_ref6 => {
    let {
      context: {
        stepToPreload,
        stepValueToPreload
      }
    } = _ref6;
    return {
      type: 'SELECT',
      selected: stepValueToPreload,
      fieldName: stepToPreload,
      selectionType: 'cache'
    };
  }, {
    delay: 0
  });
}
export function sendSelectAfterResetAction() {
  return raise(_ref7 => {
    let {
      context: {
        resetValue,
        resetTo,
        resetType
      }
    } = _ref7;
    return {
      type: 'SELECT',
      selected: resetValue,
      fieldName: resetTo,
      selectionType: resetType
    };
  }, {
    delay: 0
  });
}
export function assignResetValuesAction() {
  return assign(_ref8 => {
    let {
      event
    } = _ref8;
    if (isSelectEvent(event)) {
      return {
        resetTo: event.fieldName,
        resetValue: event.selected,
        resetType: event.selectionType
      };
    }
    return {};
  });
}
export function resetValuesAndStackAction() {
  return assign((_ref9, params) => {
    var _ctx$groupType;
    let {
      context: {
        stack,
        resetTo,
        values,
        selectedFields,
        ...ctx
      }
    } = _ref9;
    const groupType = params.nodeType;
    const newStack = resetStackUntil(stack, resetTo);
    //@ts-expect-error
    const fieldsGroup = (_ctx$groupType = ctx[groupType]) !== null && _ctx$groupType !== void 0 ? _ctx$groupType : [resetTo];
    const updatedValues = clearValues(values, resetTo, fieldsGroup, '');
    let nextSelectedFields = {
      ...selectedFields
    };
    clearSelectedFieldsForGroup(resetTo, nextSelectedFields);
    return {
      stack: newStack,
      values: updatedValues,
      selectedFields: nextSelectedFields,
      previousSelectedFieldsMap: reverseObject(nextSelectedFields)
    };
  });
}
export function selectFieldToFocusAction() {
  return assign(_ref10 => {
    let {
      event
    } = _ref10;
    if (!isFocusInputEvent(event)) {
      return {};
    }
    return {
      updatingField: event.fieldName
    };
  });
}
export function pushSelectEventToDataLayerAction(_ref11, trackLeadFieldChanged) {
  let {
    event
  } = _ref11;
  if (!isSelectEvent(event)) {
    return;
  }
  if (event.selectionType) {
    trackLeadFieldChanged(event.fieldName, event.selectionType, event.selected ? {
      fieldValue: event.selected
    } : undefined);
  }
}
export const blurFieldAction = assign(_ref12 => {
  let {
    event
  } = _ref12;
  if (event.type !== 'BLUR_INPUT') {
    return {};
  }
  return {
    updatingField: ''
  };
});
export const commitValuesAction = assign(_ref13 => {
  let {
    context: {
      values
    }
  } = _ref13;
  return {
    previousValues: {
      ...values
    }
  };
});
export const questionnaire = {
  pushToDataLayerCache,
  pushDataLayerEvent,
  syncAnswerToCookie
};
function pushDataLayerEvent(track) {
  return function $pushDataLayerEvent(_ref14, params) {
    let {
      context,
      event
    } = _ref14;
    if (!isSelectEvent(event)) {
      return;
    }
    const selectionType = event.selectionType;
    if (selectionType === 'user' || selectionType === 'cache') {
      track(event.fieldName, selectionType, event.selected ? {
        fieldValue: event.selected
      } : undefined);
      const autofillType = selectionType === 'user' ? 'autofill' : 'cache';
      getSkippedFields(context, params).forEach(field => {
        track(field, autofillType);
      });
    }
  };
}
function pushToDataLayerCache(track) {
  return function $pushToDataLayerCache(_ref15, params) {
    let {
      context
    } = _ref15;
    track(params.fieldName, 'cache');
    getSkippedFields(context, params).forEach(field => {
      track(field, 'cache');
    });
  };
}
function syncAnswerToCookie(_ref16, params) {
  let {
    event
  } = _ref16;
  const cookies = new Cookies();
  // TODO: check this event
  if (!isSelectEvent(event)) {
    return;
  }
  const {
    fieldName,
    selected
  } = event;
  const {
    cookieName,
    nextField
  } = params;
  const isSellpointSelected = selected === 'SELLPOINT_NOT_PLANNED';
  const isNextFieldEmail = nextField === 'email';
  if (!cookieName || !fieldName || !selected) {
    return;
  }
  const cookieOptions = {
    maxAge: 900,
    path: '/'
  };
  try {
    cookies.set(cookieName, selected, cookieOptions);

    // [CFE-15982] check if SELLPOINT_NOT_PLANNED was selected
    //and remove surveySelltypes cookies cos there is no sell type for it
    if (isSellpointSelected && isNextFieldEmail) {
      cookies.remove('surveySelltypes', cookieOptions);
    }
  } catch (error) {
    console.error('[Error handling cookies]:', error);
  }
}
function getSkippedFields(context, params) {
  const {
    guards,
    nextField
  } = params;
  return guards && nextField ? Object.keys(guards).filter(key => {
    return context.questionnaireFields.includes(key) && key !== nextField;
  }) : [];
}
function clearSelectedFieldsForGroup(initial, selectedFields) {
  let nextField = selectedFields[initial];
  while (nextField) {
    const next = selectedFields[nextField];
    delete selectedFields[nextField];
    nextField = next;
  }
}
function resetStackUntil(stack, step) {
  const indexOfStep = stack.indexOf(step);
  if (indexOfStep < 0) {
    return stack;
  }
  return stack.slice(0, indexOfStep);
}
function clearValues(values, step, orderedSteps, defaultValue) {
  const indexOfStep = orderedSteps.indexOf(step);
  if (indexOfStep < 0 || indexOfStep >= orderedSteps.length) {
    return values;
  }
  const otherValues = Object.entries(values).reduce(function sReducer(acc, _ref17) {
    let [key, value] = _ref17;
    if (!orderedSteps.includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {});
  const stepsToKeep = orderedSteps.slice(0, indexOfStep);
  const newValues = Object.entries(values).reduce(function reducer(acc, _ref18) {
    let [key, value] = _ref18;
    if (stepsToKeep.includes(key)) {
      acc[key] = value;
    } else {
      acc[key] = defaultValue;
    }
    return acc;
  }, {});
  return {
    ...newValues,
    ...otherValues
  };
}
function reverseObject(obj) {
  const inverse = {};
  for (const item of Object.entries(obj)) {
    inverse[item[1]] = item[0];
  }
  return inverse;
}